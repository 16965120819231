import React, { Component } from "react";
import { Grid, Row } from "react-bootstrap";
import { ModuleMainCard } from "./ModuleMainCard.jsx";
import { ModuleCard } from "./ModuleCard.jsx";
import {
  Button,
  ButtonGroup,
  ControlGroup,
  InputGroup,
  HTMLSelect,
  Spinner,
  Divider,
} from "@blueprintjs/core";
import config from "../../variables/Constants.jsx";
import ModuleInsert from "./Form/Module/ModuleInsert.jsx";
import { AppConfig } from "../../config/config.js";
import Dashboard from "../Dashboard/Dashboard.jsx";
import withUseApiService from "../../utils/withUseApiService.jsx";

class DataKeeper extends Component {
  constructor(props, context) {
    super(props, context);
    this.apiService = this.props.apiService;
    this.state = {
      data: [],
      loaded: false,
      error: false,
      errorMessage: "",
      time: null,

      // For Handle Button Set in ModuleMain card
      buttonSet: null,

      // handle Modal state
      showGoogleMainModal: false,
      paperWizadShow: false,
      PaperWizadID: null,

      showModuleInsertModal: false,
      searchData: ""
    };

    this.PaperWizadIDSet = (Value) => {
      this.setState({ PaperWizadID: Value });
    };

    this.PaperWizadModuleName = (Value) => {
      this.setState({ PaperWizadModuleName: Value });
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = () => {
      this.setState({ show: !this.state.show });
    };

    this.toggleshow = () => {
      this.setState({
        GoogleSheetInsertViewShow: !this.state.GoogleSheetInsertViewShow,
      });
    };

    this.handleCTHide = () => {
      this.setState({ CTshow: false });
    };

    this.handleCTShow = this.handleCTShow.bind(this);
  } 

  ToggleGoogleSheetInsertView = () => {
    this.setState({
      GoogleSheetInsertViewShow: !this.state.GoogleSheetInsertViewShow,
    });
  };

  handleCTShow = (ModuleID) => {
    this.setState({ CTshow: true, CTModuleID: ModuleID });
  };

  ToggleModuleState = async (ID) => {
    this.apiService.getApiInstance()
      .patch(
        AppConfig.serviceUrls.modules + ID + '/activation'
      )
      .then((res) => {
        if (res.status === 200) {
          //this.dataFetch();
          this.props.handleClick(
            "tr",
            "success",
            "Module state has been toggled successfully",
            "pe-7s-speaker"
          );
        }
        if (res.status === 400) {
          this.setState({
            ToggleStateMessage: "Bad Request From Front End",
          });
        }
      })
      .catch((err) => {
        this.setState({
          ToggleStateMessage:
            "Service down check the Server or Check your Connection  ",
        });
      });
  };

  dataFetch = async () => {
    this.setState({
      loaded: false,
      time: Date(Date.now()),
    });

    this.apiService.getApiInstance()
      .get(
        AppConfig.serviceUrls.modules
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            data: res.data.examModules,
            loaded: true,
            error: false,
            errorMessage: "",
          });
        } else {
          this.setState({
            loaded: true,
            error: true,
            errorMessage: "Data Fetching Error Occured",
          });
        }
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          error: true,
          errorMessage: "Connection Error Occured",
        });
      });
  };

  componentDidMount() {
    this.dataFetch();
  }

  handleShow = (selectionID) => {
    if (selectionID === 1) {
      this.setState({
        showModuleInsertModal: !this.state.showModuleInsertModal,
      });
    }
    // Google Sheet Insert Modal View Toggle
    if (selectionID === 2) {
      this.setState({ showGoogleMainModal: !this.state.showGoogleMainModal });
    }
  };

  render() {
    var Content;
    if (this.state.error) {
      Content = <ErrorMessage ErrorMessage={this.state.errorMessage} />;
    } else if (!this.state.loaded) {
      Content = <LoadingMessage />;
    } else {        
      Content =
        this.state.data.length > 0 ? (
          <ModuleList
            data={this.state.data}
            handleCTShow={this.handleCTShow}
            dataFetch={this.dataFetch}
            ToggleModuleState={this.ToggleModuleState}
            PaperWizadIDSet={this.PaperWizadIDSet}
            PaperWizadID={this.state.PaperWizadID}
            PaperWizadModuleName={this.PaperWizadModuleName}
            Notification={this.props.handleClick}
            searchData={this.searchData}
          />
        ) : (
          <ModuleEmptyMessage />
        );
    }

    const saveSearchData = (event) => {
      this.setState({ searchData: event.target.value })
    }

    return (
      <div className="content">
        <Dashboard />

        {/* Module Insert Drawer */}
        <ModuleInsert
          show={this.state.showModuleInsertModal}
          handleHide={() => this.handleShow(1)}
          dataFetch={this.dataFetch}
          handleClick={this.props.handleClick}
        />
        <Grid fluid>
          <div className="pull-right">
            <ControlGroup>
              <HTMLSelect options={["Module Name", "Product Name"]} />
              <InputGroup placeholder="Search" onChange={(event) => saveSearchData(event)} />
              <Button icon="arrow-right"/>
              <Divider />
              <ButtonGroup minimal={true}>
                <Button icon="add" onClick={() => this.handleShow(1)}>
                  Add
                </Button>
              </ButtonGroup>
            </ControlGroup>
          </div>
          <Row>
            <ModuleMainCard
              title="List of exam modules"
              content={Content}
              buttonSet={this.state.buttonSet}
              stats={this.state.time}
              showButton={this.state.PaperWizadID === null}
            />
          </Row>
        </Grid>
      </div>
    );
  }
}

class ModuleList extends React.Component {
  render() {
    var rows = [];
    console.log("this.props.searchData", this.props.searchData);

    this.props.data
      .filter((value) => {
        if(this.props.searchData === "") {

          return value
        } else if ((value.moduleName|| '').toLowerCase().includes((this.props.searchData|| '').toLowerCase())){
          return value
        }
      })
      .map((repo, index) =>
        rows.push(
          <ModuleCard
            key={index}
            handleCTShow={this.props.handleCTShow}
            dataFetch={this.props.dataFetch}
            id={repo.moduleId}
            IsActive={repo.isActive}
            repo={repo}
            checkValue={repo.isActive}
            title={repo.moduleName}
            productID={repo.productId}
            Product={repo.productName}
            questionType={repo.questionType}
            stats={repo.timestamp}
            ToggleModuleState={this.props.ToggleModuleState}
            PaperWizadIDSet={this.props.PaperWizadIDSet}
            PaperWizadID={this.props.PaperWizadID}
            PaperWizadModuleName={this.props.PaperWizadModuleName}
            Notification={this.props.Notification}
          />
        )
      );
    return <Row>{rows}</Row>;
  }
}

ModuleList.defaultProps = {
  data: [],
};

// Start Functional Component---------------------------------------
function LoadingMessage(props) {
  return (
    <center>
      <h4>
        <Spinner size={30} />
        <p className="category">Loading...</p>
      </h4>
    </center>
  );
}

function ErrorMessage(props) {
  return (
    <center>
      <h4>
        <i className="fa fa-exclamation-triangle" />
        <p className="category">{props.ErrorMessage}</p>
      </h4>
    </center>
  );
}

function ModuleEmptyMessage(props) {
  return (
    <center>
      <h4>
        <i className="fa fa-search" />
        <p className="category">No module data to display</p>
      </h4>
    </center>
  );
}

export default withUseApiService(DataKeeper);
